import styled from '@emotion/styled'
import { BREAKPOINTS, TYPOGRAPHY_PRESETS } from '@classy/campaign-page-blocks'
import { css } from '@emotion/react'

export const CartContainer = styled.div`
  background-color: #f2f1ef;
  height: 100%;
  margin-block-start: 5vh;
  border-radius: 20px 20px 0px 0px;

  display: grid;
  // Expected rows are: Styled.CartHeader, Styled.CartBody, Styled.CartFooter
  grid-template-rows: max-content 1fr max-content;

  &.cart-container {
    transform: translateY(100%);
  }

  @keyframes slide-in {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  &.cart-container[data-transition-status='initial'] {
    animation: slide-in 0.5s forwards;
    display: none;
  }

  &.cart-container[data-transition-status='open'] {
    animation: slide-in 0.5s forwards;
    display: grid;
  }

  &.cart-container[data-transition-status='close'] {
    animation: slide-out 0.5s forwards;
  }

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    position: absolute;
    right: 0;
    width: 500px;
    height: 100vh;
    margin-block-start: 0;
    border-radius: 0px;

    &.cart-container {
      transform: translateX(100%);
    }

    @keyframes slide-in {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0%);
      }
    }

    @keyframes slide-out {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(100%);
      }
    }
  }
`

export const CartHeader = styled.div`
  display: flex;
  padding: 24px;
  gap: 16px;
  align-self: stretch;
  color: #000;
  // prevents "pull to refresh" when the user is trying to swipe-to-close the cart dialog.
  touch-action: none;
`

export const Title = styled.h2`
  justify-content: left;
  align-items: left;
  width: 100%;
  ${TYPOGRAPHY_PRESETS.classyHeadingXs}

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    ${TYPOGRAPHY_PRESETS.classyHeadingSm}
  }
`

export const Close = styled.button`
  display: flex;
  min-width: 36px;
  min-height: 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`

export const CartError = styled.div`
  ${TYPOGRAPHY_PRESETS.classyParagraphMd};
  color: var(--classy-color__status--error-base);
  margin-block-end: 24px;
`

export const CartBody = styled.div`
  padding: 0px 24px;

  /* Needed for ScrollableCartBody to have a vertical scrollbar */
  overflow: hidden;
`

export const ScrollableCartBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  // provides spacing between cart items and scrollbar
  padding-inline-end: 24px;

  /* The following are to show a vertical scrollbar */
  height: 100%;
  overflow-y: auto;
`

export const CartSection = styled.div`
  display: flex;
  flex-direction: column;

  --cart__cart-item--gap: 12px;

  gap: var(--cart__cart-item--gap);
`

export const FrequencyLabel = styled.div`
  ${TYPOGRAPHY_PRESETS.classyParagraphMd};
  color: var(--classy-color__neutral--10);
`

export const EmptyCart = styled.p`
  ${TYPOGRAPHY_PRESETS.classyParagraphMd};
  margin: 0;
  text-align: start;
`

export const CartFooter = styled.div`
  position: relative;
  padding: 24px;

  &::before {
    content: '';
    display: inline-block;
    border-bottom: 1px solid var(--classy-color__border--input);
    /* Fade effect */
    position: absolute;
    height: 32px;
    background: linear-gradient(0deg, #f3f2f2 50%, rgba(248, 247, 247, 0) 96.87%);
    top: -32px;
    left: 24px;
    right: 24px;
  }
`

export const SubtotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--classy-color__neutral--10);
  ${TYPOGRAPHY_PRESETS.classyParagraphLgBold}
`
export const SubtotalItems = styled.div`
  color: var(--classy-color__neutral--40);
  ${TYPOGRAPHY_PRESETS.classyParagraphSm}
`

export const CheckoutButton = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  text-align: center;

  min-height: 64px;
  width: 100%;
  padding: 8px;
  margin-block-start: 24px;
  margin-block-end: 12px;

  border-radius: 80px;
  background-color: var(--classy-color__neutral--10);
  color: var(--classy-color__neutral--100);

  ${TYPOGRAPHY_PRESETS.classyParagraphLgBold}
  text-decoration: none;

  ${(props) =>
    props['aria-disabled'] === 'true' &&
    css`
      background-color: var(--classy-color__neutral--90);
      color: var(--classy-color__neutral--50);
      cursor: default;
    `}

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    margin-block-start: 40px;
  }
`

export const SecurityNote = styled.p`
  color: var(--classy-color__neutral--30);
  text-align: center;
  margin-block-end: 12px;
  ${TYPOGRAPHY_PRESETS.classyLabel}

  svg {
    vertical-align: middle;
    margin-inline-end: 4px;
  }

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    margin-block-end: 0;
  }
`
