import React from 'react'
import * as Styled from './GlobalCurrencyControl.styled'
import { getCurrencyConversion } from 'services/currency'
import { PageConfig } from 'models/pages'
import { EVENT } from 'models/event'
import { useGlobalCurrencyControlReducer } from './useGlobalCurrencyControlReducer'

interface GlobalCurrencyControlProps
  extends Pick<PageConfig, 'campaignRawCurrencyCode' | 'currencies'> {
  /**
   * The currency code detected from inspecting headers.
   */
  currencyCodeFromIpAddress: string
}

export interface CurrencyEventPayload {
  isLoading: boolean
  oldCurrencyCode: string
  newCurrencyCode: string
  currencyConversionRate: number
}

interface DispatchCurrencyConversionEventProps {
  isLoading: boolean
  newCurrencyCode: string
  currencyConversionRate: number
}

export const GlobalCurrencyControl = ({
  currencies,
  currencyCodeFromIpAddress,
  campaignRawCurrencyCode,
}: GlobalCurrencyControlProps) => {
  const [state, dispatch] = useGlobalCurrencyControlReducer(campaignRawCurrencyCode)

  const dispatchCurrencyConversionEvent = React.useCallback(
    ({
      isLoading,
      newCurrencyCode,
      currencyConversionRate,
    }: DispatchCurrencyConversionEventProps) => {
      document.dispatchEvent(
        new CustomEvent(EVENT.PASSPORT_GET_CURRENCY_CONVERSION, {
          detail: {
            isLoading,
            // The old currency code is always the campaign's default currency code.
            oldCurrencyCode: campaignRawCurrencyCode,
            newCurrencyCode,
            currencyConversionRate,
          },
        }),
      )
    },
    [campaignRawCurrencyCode],
  )

  React.useEffect(() => {
    ;(async () => {
      if (
        !state.conversion ||
        state.conversion.previousCurrencyCode === state.conversion.newCurrencyCode
      ) {
        return
      }

      /**
       * Dispatch notification that the currency conversion rate is being fetched.
       * Pass along the previous currency code and conversion rate to minimize re-renders.
       */
      dispatchCurrencyConversionEvent({
        isLoading: true,
        newCurrencyCode: state.conversion.previousCurrencyCode,
        currencyConversionRate: state.conversion.previousConversionRate,
      })

      // Always convert from the campaign's default currency code to the selected currency code
      const currencyConversionRate = await getCurrencyConversion({
        from: campaignRawCurrencyCode,
        to: state.conversion.newCurrencyCode,
      })

      dispatchCurrencyConversionEvent({
        isLoading: false,
        newCurrencyCode: currencyConversionRate.newCurrency,
        currencyConversionRate: currencyConversionRate.rate,
      })

      // The selected currency code has already been set (see handleCurrencyChange)
      dispatch({ type: 'SET_SELECTED_RATE', rate: currencyConversionRate.rate })

      /**
       * TODO: Improve error handling
       * - getCurrencyConversion() should throw an error
       * - we need direction from design on how to display an appropriate error message
       */
      if (state.conversion.newCurrencyCode !== currencyConversionRate.newCurrency) {
        dispatch({ type: 'ROLLBACK' })
      }
    })()
  }, [campaignRawCurrencyCode, dispatch, dispatchCurrencyConversionEvent, state.conversion])

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newCurrencyCode = event.target.value
    dispatch({
      type: 'TRIGGER_CHANGE_FROM_USER',
      currentCurrencyCode: state.selectedCurrencyCode,
      currentConversionRate: state.selectedConversionRate,
      newCurrencyCode,
    })
  }

  React.useEffect(() => {
    /**
     * Only accept the currency code from the IP address if:
     * - it has a value
     * - the user hasn't interacted with the dropdown yet
     * - the currencyCodeFromIpAddress prop is different from what we have in state
     */
    if (
      currencyCodeFromIpAddress &&
      !state.touched &&
      currencyCodeFromIpAddress !== state.currencyCodeFromIpAddress
    ) {
      dispatch({
        type: 'TRIGGER_CHANGE_FROM_IP_ADDRESS',
        currentCurrencyCode: state.selectedCurrencyCode,
        currentConversionRate: state.selectedConversionRate,
        newCurrencyCode: currencyCodeFromIpAddress,
      })
    }
  }, [
    currencyCodeFromIpAddress,
    dispatch,
    state.currencyCodeFromIpAddress,
    state.selectedConversionRate,
    state.selectedCurrencyCode,
    state.touched,
  ])

  const currencyOptions = React.useMemo(
    () =>
      currencies && currencies.length > 0
        ? currencies
        : [{ value: campaignRawCurrencyCode, label: campaignRawCurrencyCode }],
    [campaignRawCurrencyCode, currencies],
  )

  return (
    <Styled.Select
      aria-label="Select currency"
      onChange={handleCurrencyChange}
      value={state.selectedCurrencyCode}
    >
      {currencyOptions?.map((option) => (
        <option data-abbreviation={option.value} key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Styled.Select>
  )
}
