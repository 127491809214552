import { useImmerReducer } from 'use-immer'

export interface GlobalCurrencyControlState {
  /**
   * Primarily for preventing the user's selection from getting clobbered by the
   * currency code detected from inspecting headers.
   */
  touched: boolean
  currencyCodeFromIpAddress?: string
  selectedCurrencyCode: string
  selectedConversionRate: number
  /**
   * Information about the currency conversion rate being fetched.
   * - fromCurrencyCode, fromConversionRate: tracked in case we need to rollback due to error
   * - toCurrencyCode: the currency code we want to fetch a conversion rate for
   */
  conversion?: {
    previousCurrencyCode: string
    previousConversionRate: number
    newCurrencyCode: string
  }
}

function createInitialState(campaignRawCurrencyCode: string): GlobalCurrencyControlState {
  return {
    touched: false,
    selectedCurrencyCode: campaignRawCurrencyCode,
    selectedConversionRate: 1,
  }
}

export type GlobalCurrencyControlAction =
  | { type: 'SET_SELECTED_RATE'; rate: number }
  | {
      type: 'TRIGGER_CHANGE_FROM_IP_ADDRESS'
      currentCurrencyCode: string
      currentConversionRate: number
      newCurrencyCode: string
    }
  | {
      type: 'TRIGGER_CHANGE_FROM_USER'
      currentCurrencyCode: string
      currentConversionRate: number
      newCurrencyCode: string
    }
  | { type: 'ROLLBACK' }

function reducer(draft: GlobalCurrencyControlState, action: GlobalCurrencyControlAction) {
  switch (action.type) {
    case 'SET_SELECTED_RATE':
      draft.selectedConversionRate = action.rate
      break
    /**
     * When a user selects a currency from the dropdown.
     */
    case 'TRIGGER_CHANGE_FROM_USER':
      draft.conversion = {
        previousCurrencyCode: action.currentCurrencyCode,
        previousConversionRate: action.currentConversionRate,
        newCurrencyCode: action.newCurrencyCode,
      }
      draft.touched = true
      /**
       * For screen readers, we need to immediately set the selected currency code, otherwise the
       * screen reader might read aloud the previous currency code, not the one they just selected.
       * This happens because the async request to get the conversion rate causes a delay.
       */
      draft.selectedCurrencyCode = action.newCurrencyCode
      break
    /**
     * When currencyCodeFromIpAddress is the currency source.
     */
    case 'TRIGGER_CHANGE_FROM_IP_ADDRESS':
      draft.conversion = {
        previousCurrencyCode: action.currentCurrencyCode,
        previousConversionRate: action.currentConversionRate,
        newCurrencyCode: action.newCurrencyCode,
      }
      draft.currencyCodeFromIpAddress = action.newCurrencyCode
      draft.selectedCurrencyCode = action.newCurrencyCode
      break
    /**
     * In the case of error, rollback to the previous currency code and rate.
     */
    case 'ROLLBACK':
      if (!draft.conversion) return draft
      draft.selectedCurrencyCode = draft.conversion.previousCurrencyCode
      draft.selectedConversionRate = draft.conversion.previousConversionRate
      break
    default:
      /* @ts-expect-error -- TS complains about action.type because all actions are covered 💪 */
      throw new Error(`Unknown action: ${action.type}`)
  }
}

export const useGlobalCurrencyControlReducer = (campaignRawCurrencyCode: string) =>
  useImmerReducer(reducer, campaignRawCurrencyCode, createInitialState)
