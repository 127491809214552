import { pinoLogger } from 'utils/pino'
interface Permission {
  action: string
  entity: string
}

interface Scopes {
  organization?: Array<string | number>
  campaign?: Array<string | number>
}

interface ScopeResult {
  value: string | null
  type: string | null // organization, campaign
}

interface PermissionResult {
  action: string
  entity: string
  id: number
  result: boolean
  scopes: ScopeResult[]
}

export interface FetchMemberPermissionsResponse {
  data: PermissionResult[]
}

/**
 * The purpose of this method is to hit the /actors/member_{member_id}/auth endpoint
 * in order to retrieve details on whether a member is authorized for a given
 * set of permissions and scopes. Only use this service server-side to not expose the Cerberus
 * API key.
 *
 * https://docs.classy-test.org/cerberus/
 */
export async function fetchMemberPermissions(args: {
  memberId: number
  permissions: Permission[]
  scopes: Scopes
  include_children?: boolean
}): Promise<FetchMemberPermissionsResponse> {
  const BASE_URL = `${process.env.KONG_BASE_URI}/cerberus/1.0`
  const API_KEY = process.env.CERBERUS_API_KEY ?? ''

  const headers = {
    'X-API-KEY': API_KEY,
    'X-Namespace': 'fundraising',
    'Content-Type': 'application/json',
  }

  const { memberId, permissions, scopes, include_children = false } = args

  try {
    const params = new URLSearchParams({ include_children: include_children ? '1' : '0' })

    const permissionsResponse = await fetch(
      `${BASE_URL}/actors/member_${memberId}/auth?${params}`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify({ permissions, scopes }),
      },
    )

    if (!permissionsResponse.ok) {
      pinoLogger.error(
        {
          status: permissionsResponse.status,
          statusText: permissionsResponse.statusText,
          url: permissionsResponse.url,
        },
        'Error: permissions response is not ok',
      )

      throw new Error(
        `Failed to fetch member permissions. Status code: ${permissionsResponse.status}`,
      )
    }

    return await permissionsResponse.json()
  } catch (e) {
    pinoLogger.error(e)
    throw e
  }
}

/**
 * Returns true if all fetched permissions have "result: true".
 *
 * * This helper is meant to be used directly with fetchMemberPermissions()
 */
export const hasAllPermissions = (fetchResponse: FetchMemberPermissionsResponse) =>
  fetchResponse?.data?.length > 0 &&
  fetchResponse?.data.every((permissionResult) => permissionResult.result === true)
