interface WhammyApiError extends Error {
  statusCode: number
}

interface WhammyApiErrorParams {
  message: string
  statusCode: number
}

/**
 * This function allows devs to create a Javascript error and attach a status code for any non-2xx
 * response from external apis hit within the whammy api (/api/*). The whammy api is used client
 * side to hit external apis such as APIv2 and the Cart API. If there are any issues with the
 * request or response, throwing a WhammyApiError should be the bottom of the stack as it would be
 * the first error thrown. As such, we currently don't provide the option to add a cause to the
 * error object as an ErrorOption but this can be added at any time if the need arises.
 */
export const WhammyApiError = (
  { message, statusCode }: WhammyApiErrorParams,
  options?: ErrorOptions,
): WhammyApiError => {
  const error = new Error(message, options) as WhammyApiError
  error.name = 'WhammyApiError'
  error.statusCode = statusCode
  return error
}

export const isWhammyApiError = (error: unknown): error is WhammyApiError => {
  return error instanceof Error && error.name === 'WhammyApiError'
}
