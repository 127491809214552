import {
  DonationAmount,
  DonationBlockOnInitialization,
  FREQUENCY,
} from '@classy/campaign-page-blocks'
import { getAnalyticsInstance } from 'services/analytics'

export interface DonationFormInitPayload {
  organization_id: string
  campaign_type: string
  campaign_id: string
  cart_enabled: boolean
  dcf_enabled: boolean
  default_value: number | null
  currency_code: string
  default_frequency: string
  /** Example: "500,100,50,25,Custom" */
  amounts_onetime: string | null
  /** Example: "500,100,50,25,Custom" */
  amounts_recurring: string | null
  iaa_enabled_onetime: boolean
  iaa_enabled_recurring: boolean
  iaa_amount_onetime: number
  iaa_amount_recurring: number
  /** Example: "100,220,505,670" */
  iaa_amounts_onetime: string | null
  /** Example: "90,130,235,300" */
  iaa_amounts_recurring: string | null
  url_amount_used: number
  page_position: string
}

export interface TrackDonationFormInitParams {
  initBlockstate: DonationBlockOnInitialization
  urlAmountUsed: string
  sectionId?: string
}

interface AmountArraytoStringParams {
  amountsArray: DonationAmount[]
  iaaEnabled?: boolean
}

const amountArraytoString = ({
  amountsArray = [],
  iaaEnabled = false,
}: AmountArraytoStringParams) => {
  const amounts = amountsArray.map((amount) => {
    if (amount?.name === 'other' && !iaaEnabled) return 'Custom'
    return amount.amount
  })

  return amounts.join(',')
}

/**
 * Track DonationBlocks initialized state
 */
export const trackDonationFormInitEvent = ({
  initBlockstate,
  urlAmountUsed,
  sectionId,
}: TrackDonationFormInitParams) => {
  const analytics = getAnalyticsInstance()

  const {
    amount,
    campaignId,
    currencyCode,
    dcfEnabled,
    frequency,
    isCartEnabled,
    orgId,
    visibleDonationAmounts,
    visibleRecurringAmounts,
  } = initBlockstate

  const { onetimeEnabled, donationBaseAmount, recurringEnabled, recurringBaseAmount } =
    initBlockstate.iaa

  const iaaEnabled = initBlockstate.iaa.enabled
  const iaaAmount = Number(
    frequency === FREQUENCY.ONE_TIME ? donationBaseAmount : recurringBaseAmount,
  )

  const heapPayload: DonationFormInitPayload = {
    organization_id: orgId || '',
    campaign_type: 'studio',
    campaign_id: campaignId || '',
    cart_enabled: isCartEnabled || false,
    dcf_enabled: dcfEnabled || false,
    default_value: !iaaEnabled ? amount : iaaAmount,
    currency_code: currencyCode,
    default_frequency: frequency,
    amounts_onetime: !iaaEnabled
      ? amountArraytoString({ amountsArray: visibleDonationAmounts })
      : null,
    amounts_recurring: !iaaEnabled
      ? amountArraytoString({ amountsArray: visibleRecurringAmounts })
      : null,
    url_amount_used: Number(urlAmountUsed || 0),
    page_position: sectionId || '',
    // Intelligent Ask Amount
    iaa_enabled_onetime: onetimeEnabled,
    iaa_enabled_recurring: recurringEnabled,
    iaa_amount_onetime: Number(donationBaseAmount),
    iaa_amount_recurring: Number(recurringBaseAmount),
    iaa_amounts_onetime: iaaEnabled
      ? amountArraytoString({ amountsArray: visibleDonationAmounts, iaaEnabled })
      : null,
    iaa_amounts_recurring: iaaEnabled
      ? amountArraytoString({ amountsArray: visibleRecurringAmounts, iaaEnabled })
      : null,
  }

  // Heap
  analytics.track('studio_page/donation_form_init', heapPayload, {
    plugins: {
      all: false,
      heap: true,
    },
  })
}
