import { EVENT as BLOCK_EVENTS } from '@classy/campaign-page-blocks'

export const EVENT = {
  ...BLOCK_EVENTS,
  ANALYTICS_TRACK_ADD_TO_CART: 'analytics.trackAddToCart',
  // Intelligent Ask Amount
  IAA_FETCH_AMOUNTS: 'iaa.fetch-amounts',
  PASSPORT_GET_CURRENCY_CONVERSION: 'passport.get-currency-conversion',
  SEARCH_GET_CAMPAIGN_PROGRESS_METRICS: 'search.get-campaign-progress-metrics',
}

export type Event = (typeof EVENT)[keyof typeof EVENT]
