import { currencyFetch } from 'classy-api-proxies/currency'
import { HttpMethod } from 'types/http'
import { logger } from 'utils/logger'

interface GetCurrencyConversionProps {
  from: string
  to: string
}
export const getCurrencyConversion = async ({ from, to }: GetCurrencyConversionProps) => {
  /**
   * if the fetch fails, we will want to fallback to the previous currency code aka `from`
   */
  let currencyConversion = {
    oldCurrency: from,
    newCurrency: from,
    date: new Date().toISOString().split('T')[0],
    rate: 1.0,
  }

  if (from === to) {
    return currencyConversion
  }

  try {
    const response = await fetch(`/api/currency/${from}/${to}`, {
      method: HttpMethod.GET,
      // Abort the fetch if it takes longer than 1 second
      signal: AbortSignal.timeout(1000),
    })

    const { rate, date } = await response.json()

    if (rate) {
      currencyConversion = {
        oldCurrency: from,
        newCurrency: to,
        rate,
        date,
      }
    }
  } catch (e) {
    if ((e as Error).name === 'TimeoutError') {
      logger(
        'info',
        new Error(
          `Unable to fetch currency conversion in less than 1 second from ${from} to ${to}`,
          {
            cause: e,
          },
        ),
      )
    } else {
      logger(
        'error',
        new Error(`Unable to fetch currency conversion from ${from} to ${to}`, {
          cause: e,
        }),
      )
    }
  }

  return currencyConversion
}

export const getAvailableCurrencies = async (defaultCurrencyCode: string) => {
  const today = new Date().toISOString().split('T')[0]
  let availableCurrencies = {
    currencies: [defaultCurrencyCode],
    date: today,
  }

  try {
    const response = await currencyFetch(`/currencies/${today}`, {
      method: HttpMethod.GET,
      // Abort the fetch if it takes longer than 1 second
      signal: AbortSignal.timeout(1000),
    })

    const { currencies, date } = await response.json()

    if (currencies.length) {
      availableCurrencies = {
        currencies,
        date,
      }
    }
  } catch (e) {
    if ((e as Error).name === 'TimeoutError') {
      logger(
        'info',
        new Error(`Unable to fetch available currencies in less than 1 second for ${today}`, {
          cause: e,
        }),
      )
    } else {
      logger(
        'error',
        new Error(`Unable to fetch available currencies for ${today}`, {
          cause: e,
        }),
      )
    }
  }

  return availableCurrencies
}

export interface AllCurrenciesDto {
  currency_code: string
  is_disabled: number
  name: string
  precision: number
}

export const getAllCurrencies = async (defaultCurrencyCode: string) => {
  let supportedCurrencies = [
    { currency_code: defaultCurrencyCode, is_disabled: 0, name: defaultCurrencyCode, precision: 2 },
  ]

  try {
    const response = await currencyFetch(
      // Fetch all currencies that are not disabled and have a precision less than 5.
      // This is to prevent fetching crypto currencies (BTC, ETH)
      `/currencies/all?filter=is_disabled!=1&filter=precision<5`,
      {
        method: HttpMethod.GET,
        // Abort the fetch if it takes longer than 1 second
        signal: AbortSignal.timeout(1000),
      },
    )

    const { currencies } = await response.json()

    if (currencies) {
      supportedCurrencies = currencies
    }
  } catch (e) {
    if ((e as Error).name === 'TimeoutError') {
      logger(
        'info',
        new Error(`Unable to fetch supported currencies in less than 1 second`, {
          cause: e,
        }),
      )
    } else {
      logger(
        'error',
        new Error(`Unable to fetch supported currencies`, {
          cause: e,
        }),
      )
    }
  }

  return supportedCurrencies
}

/**
 * Get currency options for the currency select dropdown
 */
export const getCurrencyOptions = async (currencyCode: string) => {
  const supportedCurrencies = await getAllCurrencies(currencyCode)
  const availableCurrencies = await getAvailableCurrencies(currencyCode)
  const currencies = supportedCurrencies
    ?.filter((currency) => availableCurrencies?.currencies.includes(currency.currency_code))
    .map((currency) => ({
      value: currency.currency_code,
      label: `${currency.currency_code} | ${currency.name}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return currencies
}
