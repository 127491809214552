import React from 'react'
import { logger } from 'utils/logger'
import { Countries } from 'country-to-currency'

export interface ClientHeadersData {
  cfConnectingIp: string
  geoCountry: Countries
}

export const useClientHeadersData = () => {
  const [clientHeadersData, setClientHeadersData] = React.useState<ClientHeadersData>({
    cfConnectingIp: '',
    geoCountry: 'US',
  })

  const fetchClientHeadersData = React.useCallback(async () => {
    try {
      const response = await fetch('/api/clientHeadersData', {
        // Abort the fetch if it takes longer than 1 second
        signal: AbortSignal.timeout(1000),
      })

      const json = await response.json()

      setClientHeadersData(json)
    } catch (e) {
      if ((e as Error).name === 'TimeoutError') {
        logger(
          'info',
          new Error('Unable to read request headers data in less than 1 second', {
            cause: e,
          }),
        )
      } else {
        logger(
          'error',
          new Error('Unable to read request headers data', {
            cause: e,
          }),
        )
      }
    }
  }, [])

  React.useEffect(() => {
    fetchClientHeadersData()
  }, [fetchClientHeadersData])

  return clientHeadersData
}
