import { UAParser } from 'ua-parser-js'
import { ParentLayoutInfo, ShareButton, ShareButtonProps } from '@classy/campaign-page-blocks'
import React from 'react'

interface ShareButtonControllerProps extends ShareButtonProps {
  parentLayoutInfo?: ParentLayoutInfo
}

export const ShareButtonController = ({
  parentLayoutInfo,
  ...rest
}: ShareButtonControllerProps) => {
  const device = new UAParser().getDevice()
  const isMobileOrTablet = device.type === 'mobile' || device.type === 'tablet'

  return (
    <ShareButton
      isMobileOrTablet={isMobileOrTablet}
      parentLayoutInfo={parentLayoutInfo}
      {...rest}
    />
  )
}
