import { BLOCK_TYPES, FooterProps } from '@classy/campaign-page-blocks'
import { OrganizationDto } from 'models/organization'
import { DenormalizedPageData } from 'models/pages'
import { StudioCampaignDto } from 'models/studio'

/**
 * Gets the Footer block out of the SharedBlocks object.
 */
export const getFooter = (sharedBlocks: DenormalizedPageData['sharedBlocks']) => {
  const block = [...Object.values(sharedBlocks)].find((block) => block.type === BLOCK_TYPES.FOOTER)

  if (!block) return

  // Narrow type: "PageHeaderProps | FooterProps" -> "FooterProps"
  block.props = block.props as FooterProps

  return block
}

/**
 * Footer page block props overrides.
 *
 * Attaches the organization's name and address, and the campaign's contact email and phone number.
 */
export const addFooterBlockProps = (
  blockProps: FooterProps,
  organization: OrganizationDto,
  campaign: StudioCampaignDto,
) => {
  blockProps.footer = {
    name: organization?.name,
    address: organization?.address,
    city: organization?.city,
    state: organization?.state,
    postalCode: organization?.postal_code,
    country: organization?.country,
    email: campaign?.campaign_data?.contact_email,
    phone: campaign?.campaign_data?.contact_phone,
    privacyPolicyUrl: organization?.privacy_policy_url || organization?.url,
  }
}
