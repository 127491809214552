import { BREAKPOINTS, StyledSelect } from '@classy/campaign-page-blocks'
import styled from '@emotion/styled'

export const Select = styled(StyledSelect)`
  width: 5.5rem;
  overflow: hidden;
  text-overflow: '';

  &:hover {
    background-color: var(--classy-color__neutral--95);
    cursor: pointer;
  }

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    width: 17rem;
    text-overflow: ellipsis;
  }
`
