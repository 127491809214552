import {
  ParentLayoutInfo,
  ProgressMetrics,
  ProgressMetricsProps,
  useCustomEventListener,
} from '@classy/campaign-page-blocks'
import { CurrencyEventPayload } from 'features/Passport/GlobalCurrencyControl'
import { EVENT } from 'models/event'

interface ProgressMetricsData {
  donorCount: number
  goal: number
  percentToGoal: number
  totalRaised: number
}

interface ProgressMetricsControllerProps extends ProgressMetricsProps {
  parentLayoutInfo?: ParentLayoutInfo
}

export const ProgressMetricsController = ({
  currency = 'USD',
  ...rest
}: ProgressMetricsControllerProps) => {
  const { donorCount, goal, percentToGoal, totalRaised }: ProgressMetricsData =
    useCustomEventListener(EVENT.SEARCH_GET_CAMPAIGN_PROGRESS_METRICS, {
      donorCount: 0,
      goal: 0,
      percentToGoal: 0,
      totalRaised: 0,
    })

  const { currencyConversionRate, newCurrencyCode }: CurrencyEventPayload = useCustomEventListener(
    EVENT.PASSPORT_GET_CURRENCY_CONVERSION,
    {
      oldCurrencyCode: currency,
      newCurrencyCode: currency,
      currencyConversionRate: 1,
      isLoading: false,
    },
  )

  const convertedGoal = goal * currencyConversionRate
  const convertedTotalRaised = totalRaised * currencyConversionRate

  return (
    <ProgressMetrics
      currency={newCurrencyCode}
      donorCount={donorCount}
      goal={convertedGoal}
      percentToGoal={percentToGoal}
      totalRaised={convertedTotalRaised}
      {...rest}
    />
  )
}
