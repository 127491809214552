import React from 'react'
import { getOrganizationTagByName } from 'services/organization'
import { logger } from 'utils/logger'

/**
 * This hook ensures the appropriate Optimizely script is loaded for every campaign page.
 *
 * By default, the Classy Optimizely script is utilized for all organizations.
 *
 * However, if an organization has the org tag `zz-skip-optimizely`,
 * this hook permits the use of their specific Optimizely script instead.
 *
 * In such cases, the Classy Optimizely script is removed,
 * along with the Classy Optimizely Object from the window,
 * allowing the organization's custom Optimizely script to take precedence.
 */

export const useOptimizely = (organizationId?: string) => {
  const optimizely = React.useCallback(async () => {
    if (!organizationId) return

    let skipOptimizelyTag

    try {
      skipOptimizelyTag = await getOrganizationTagByName({
        organizationId: organizationId,
        tagName: 'zz-skip-optimizely',
      })
    } catch (e) {
      logger(
        'error',
        new Error(`Unable to fetch zz-skip-optimizely for ${organizationId}`, {
          cause: e,
        }),
      )
    } finally {
      /**
       * if organizationTag fetch fails, default skipClassyOptimizely to false
       */
      const skipClassyOptimizely = skipOptimizelyTag?.data?.length == 1 || 0

      const scripts = document.querySelectorAll('script')
      for (let i = 0; i < scripts.length; i++) {
        const script = scripts[i]
        if (script.src.startsWith('https://cdn.optimizely.com/js/')) {
          /**
           * Remove 3rd party Optimizely scripts if:
           *
           * - the organizationId does NOT have the zz-skip-optimizely tag
           * - the script ID does not match `classy-optimizely`
           */
          if (!skipClassyOptimizely && script.id !== 'classy-optimizely') {
            script.parentNode?.removeChild(script)
            logger('info', `Removing Optimizely script`, {
              context: { organizationId, script },
            })
          }

          /**
           * Remove the Classy Optimizely script
           * & remove the Classy Optimizely object from the window if:
           *
           * - the organizationId has the zz-skip-optimizely tag
           * - the script ID matches `classy-optimizely`
           */
          if (skipClassyOptimizely && script.id === 'classy-optimizely') {
            script.parentNode?.removeChild(script)
            logger('info', `Removing Classy Optimizely script`, {
              context: { organizationId, script },
            })

            if (window.optimizely) {
              window.optimizely = undefined
              logger('info', `Removing Classy Optimizely from window}`, {
                context: { organizationId },
              })
            }
          }
        }
      }
    }
  }, [organizationId])

  React.useEffect(() => {
    optimizely()
  }, [organizationId, optimizely])
}
