import { Frequency } from '@classy/campaign-page-blocks'

export const PRODUCT_TYPES = {
  RECURRING: 'recurring',
  DONATION: 'donation',
} as const

export type ProductType = (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES]

export type Cart = {
  id?: string
  campaignId: string
  currency: string
  items?: CartItem[]
}

export type CartItem = {
  id?: string
  productType: ProductType
  name: string
  description?: string
  qty: number
  unitPrice: number
  designationId: number
  recurringProperties?: CartItemRecurringProperties
}

export type CartItemRecurringProperties = {
  endDate?: string // yyyy-mm-dd
  frequency: Frequency
  timeZone: string // e.g. "America/New_York", "Europe/London", etc.
}
