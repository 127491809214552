import { PAGE_TYPES, PageConfig, PageType } from 'models/pages'

/**
 * Utilities related to the HEAD section of each page.
 *
 * In Whammy, the preview page covers all page types. The following functions ensure consistency
 * between real pages and the preview page.
 */

/**
 * Generate page title. Necessary for the preview page.
 *
 * Note, a custom component (e.g. <PageTitle pageType={} />) would have been nicer, but
 * Next.js expects <title> to be an immediate descendent of <Head>.
 */
export const getPageTitle = (pageType: PageType, name: string) => {
  switch (pageType) {
    case PAGE_TYPES.LANDING:
      return `Campaign page for ${name}`
    case PAGE_TYPES.DONATION:
      return `Donate to ${name}`
    case PAGE_TYPES.THANK_YOU:
      return `Thank you from ${name}`
    default:
      return ''
  }
}

/**
 * Generate page meta description.
 *
 * Note, a custom component (e.g. <PageMetaDescription pageType={} />) would have been
 * nicer, but Next.js expects <meta> to be an immediate descendent of <Head>.
 */
export const getPageMetaDescription = (pageType: PageType, pageConfig: PageConfig) => {
  switch (pageType) {
    case PAGE_TYPES.LANDING:
      return `${pageConfig?.orgName}'s campaign page.`
    case PAGE_TYPES.DONATION:
      return `${pageConfig?.orgName}'s campaign donation page.`
    case PAGE_TYPES.THANK_YOU:
      return `${pageConfig?.orgName}'s thank you page.`
    default:
      return ''
  }
}
