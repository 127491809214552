import React from 'react'
import { ShoppingCart } from '@classy/campaign-page-blocks'
import * as Styled from './CartButton.styled'

export interface CartButton {
  cartCount: number
  isLoading: boolean
  // innerRef provided by floating-ui
  innerRef?: React.Ref<HTMLElement>
}

export const CartButton = ({ cartCount, isLoading, ...rest }: CartButton) => {
  return (
    <Styled.CartButton {...rest}>
      <ShoppingCart />
      Cart {isLoading ? '' : cartCount}
    </Styled.CartButton>
  )
}
