import React from 'react'
import NextImage from 'next/image'
import {
  Footer,
  ClassyFooter,
  PageHeader,
  ComputedUserTheme,
  useCustomEventListener,
  Member,
} from '@classy/campaign-page-blocks'
import countryToCurrency from 'country-to-currency'
import { BlockBuilder } from 'features/Block/BlockBuilder'
import { MemberDropdown } from './MemberDropdown'
import { useSsoMember } from 'hooks/useSsoMember'
import { DenormalizedPageData, PageConfig } from 'models/pages'
import { EVENT } from 'models/event'
import { SsoSdk } from './SsoSdk'
import { getCampaignProgressMetrics } from 'services/campaign'
import { onLogin } from 'services/sso'
import {
  trackAddToCartEvent,
  AnalyticsTrackAddToCartEvent,
} from 'services/analytics/events/addToCart'
import { trackCustomPageView } from 'services/analytics/events/customPageView'
import { trackPageViewEvent } from 'services/analytics/events/pageView'
import { useConsentManager } from 'services/transcend'
import { CartController } from 'features/Cart'
import { logger } from 'utils/logger'
import { useOptimizely } from 'hooks/useOptimizely'
import { useClientHeadersData, ClientHeadersData } from 'hooks/useClientHeadersData'
import { getFooter } from 'features/Block/footer/footer'
import { getPageHeader } from 'features/Block/page-header/page-header'
import { GlobalCurrencyControl } from 'features/Passport/GlobalCurrencyControl'
import { Environment } from 'utils/environment'

export interface CampaignPageProps
  extends Pick<DenormalizedPageData, 'theme' | 'sharedBlocks' | 'pageData'> {
  __ENV?: Environment
  isAnalyticsLoaded?: boolean
  pageConfig: PageConfig
}

/**
 * This component assumes all campaign pages share the same layout.
 */
export const CampaignPage = ({
  __ENV,
  pageData,
  sharedBlocks,
  theme,
  pageConfig,
  isAnalyticsLoaded,
}: CampaignPageProps) => {
  const [isSsoSdkLoaded, setIsSsoSdkLoaded] = React.useState(false)

  useOptimizely(pageConfig.orgId)

  const pageHeaderProps = getPageHeader(sharedBlocks)?.props
  const footerProps = getFooter(sharedBlocks)?.props

  const member = useSsoMember(isSsoSdkLoaded)

  /**
   * Fetching the client's headers data client side, instead of getServerSideProps
   * to prevent caching the IP address and currency.
   */
  const clientHeadersData: ClientHeadersData = useClientHeadersData()

  /**
   * Find the user's local currency code using their IP address (via Cloudflare)
   */
  const currencyCodeFromIpAddress = clientHeadersData
    ? countryToCurrency[clientHeadersData.geoCountry]
    : pageConfig.campaignRawCurrencyCode

  const { airgap } = useConsentManager()

  // * GA4 Custom Page View Tracking Event
  React.useEffect(() => {
    if (airgap && isAnalyticsLoaded && member !== undefined) {
      trackCustomPageView(pageConfig, member)
    }
  }, [airgap, isAnalyticsLoaded, pageConfig, member])

  /**
   * Track PageView Event for Facebook Pixel and Meta CAPI
   */
  React.useEffect(() => {
    /**
     * Fire trackPageViewEvent if airgap, isAnalyticsLoaded, clientHeadersData.cfConnectingIp are
     * true and only if member !== undefined. If member === undefined, then useSsoMember has not
     * finished checking whether a member is logged in or not.
     */
    if (airgap && isAnalyticsLoaded && clientHeadersData.cfConnectingIp && member !== undefined) {
      trackPageViewEvent({ member, client_ip: clientHeadersData.cfConnectingIp })
    }
  }, [airgap, clientHeadersData.cfConnectingIp, isAnalyticsLoaded, member])

  /**
   *   Custom Event Listener for Facebook Pixel and Meta CAPI trackAddToCartEvent analytics
   */
  const addToCartEventData = useCustomEventListener<AnalyticsTrackAddToCartEvent | null>(
    EVENT.ANALYTICS_TRACK_ADD_TO_CART,
    null,
  )

  React.useEffect(() => {
    if (addToCartEventData && addToCartEventData.newCartItem) {
      const { newCartItem, cartCount } = addToCartEventData

      trackAddToCartEvent({
        newCartItem,
        cartCount,
        clientIpAddress: clientHeadersData.cfConnectingIp,
        member,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToCartEventData])

  // Used for A/B testing by Cro Metrics. The SC type name comes from an appv2 convention and stands
  // for StayClassy (the old name of the company).
  React.useEffect(() => {
    window.SC = {
      ab_testing: {
        cid: Number(pageConfig.campaignId),
        oid: Number(pageConfig.orgId),
        donation_type: 'studio',
        campaign_type: 'studio',
        features: pageConfig.isCartEnabled ? ['cart'] : [],
      },
    }
  }, [pageConfig.campaignId, pageConfig.orgId, pageConfig.isCartEnabled])

  // * Fetch campaign progress metrics from the search service
  const campaignProgressMetrics = React.useCallback(async () => {
    try {
      if (pageConfig.campaignId) {
        const { donorCount, goal, percentToGoal, totalRaised } = await getCampaignProgressMetrics(
          pageConfig.campaignId,
        )

        // * Dispatch campaign data for interested blocks
        document.dispatchEvent(
          new CustomEvent(EVENT.SEARCH_GET_CAMPAIGN_PROGRESS_METRICS, {
            detail: {
              donorCount,
              goal,
              percentToGoal,
              totalRaised,
            },
          }),
        )
      }
    } catch (e) {
      logger(
        'error',
        new Error('Unable to fetch campaign details from Classy Search', { cause: e }),
      )
    }
  }, [pageConfig.campaignId])

  React.useEffect(() => {
    campaignProgressMetrics()
  }, [campaignProgressMetrics])

  // Member info is a truncated member object for use by page blocks. See GlobalBlockProps for info.
  let memberInfo: Member | undefined
  if (member) {
    memberInfo = {
      id: String(member.id),
      avatar: member.thumbnail_small,
      canDeleteComments: false,
    }
  }

  const showGlobalCurrencyControl = !pageConfig.isCartEnabled && !pageConfig.isPassportDisabled
  const showCartController =
    pageConfig.isCartEnabled && pageConfig.campaignId && pageConfig.checkoutBaseUrl

  return (
    <>
      <ComputedUserTheme theme={theme} />
      {pageHeaderProps && (
        <PageHeader hidePlaceholder NextImage={NextImage as never} {...pageHeaderProps}>
          {showGlobalCurrencyControl && (
            <GlobalCurrencyControl
              currencies={
                pageConfig.currencies || [
                  {
                    value: pageConfig.campaignRawCurrencyCode,
                    label: pageConfig.campaignRawCurrencyCode,
                  },
                ]
              }
              campaignRawCurrencyCode={pageConfig.campaignRawCurrencyCode}
              currencyCodeFromIpAddress={currencyCodeFromIpAddress}
            />
          )}
          {showCartController && (
            <CartController
              checkoutBaseUrl={pageConfig.checkoutBaseUrl || ''}
              checkoutQueryParams={pageConfig.forwardCheckoutQueryParams || {}}
              campaignId={pageConfig.campaignId}
            />
          )}
          {member && (
            <MemberDropdown
              member={member}
              orgId={pageConfig.orgId}
              classyBaseUri={__ENV?.classyBaseUri}
            />
          )}
          <SsoSdk onReady={() => setIsSsoSdkLoaded(true)} pageConfig={pageConfig} __ENV={__ENV} />
        </PageHeader>
      )}
      <BlockBuilder pageData={pageData} member={memberInfo} __ENV={__ENV} />
      <footer>
        {footerProps && <Footer NextImage={NextImage as never} {...footerProps} />}
        <ClassyFooter
          data-id="cp-footer-login"
          isLoggedIn={!!member}
          onLogin={() => onLogin(__ENV as Environment, pageConfig.orgId)}
        />
      </footer>
    </>
  )
}
