import { UAParser } from 'ua-parser-js'
import { ParentLayoutInfo, SocialLinks, SocialLinksProps } from '@classy/campaign-page-blocks'

interface SocialLinksControllerProps extends SocialLinksProps {
  parentLayoutInfo?: ParentLayoutInfo
}

export const SocialLinksController = ({
  parentLayoutInfo,
  ...rest
}: SocialLinksControllerProps) => {
  const device = new UAParser().getDevice()
  const isMobileOrTablet = device.type === 'mobile' || device.type === 'tablet'

  return (
    <SocialLinks
      isMobileOrTablet={isMobileOrTablet}
      parentLayoutInfo={parentLayoutInfo}
      {...rest}
    />
  )
}
