import { IncomingMessage } from 'http'
import { OrganizationDto } from 'models/organization'
import { HttpMethod } from '../../types/http'
import { APIv2Fetch } from 'classy-api-proxies/apiv2'

interface GetOrganizationDataParams {
  organizationId: string
  req: IncomingMessage
}

/**
 * Fetch organization data.
 */
export const getOrganizationData = async ({
  organizationId,
  req,
}: GetOrganizationDataParams): Promise<OrganizationDto> => {
  const response = await APIv2Fetch(
    `/organizations/${organizationId}`,
    { method: HttpMethod.GET },
    { originalRequest: req },
  )

  const organization: OrganizationDto = await response.json()

  return organization
}
