import { IncomingMessage } from 'http'
import { Block } from 'features/Block/block.model'
import { PageType } from 'models/pages'
import {
  BlockLayoutDto,
  StudioCampaignDto,
  StudioCampaignPagesDto,
  PageDto,
  CampaignDataDto,
} from 'models/studio'
import { getMockPageKey } from './mock-data'
import { HttpMethod } from '../types/http'
import { APIv2Fetch } from 'classy-api-proxies/apiv2'

/**
 * Methods for fetching, searching, and processing raw campaign data.
 */

interface GetCampaignDataParams {
  campaignId: string
  req: IncomingMessage
  published?: boolean
}

/**
 * Fetch campaign data.
 */
export const getCampaignData = async ({
  campaignId,
  req,
  published = false,
}: GetCampaignDataParams): Promise<StudioCampaignDto> => {
  const response = await APIv2Fetch(
    `/studio-campaigns/${campaignId}?published=${published}`,
    { method: HttpMethod.GET },
    { originalRequest: req },
  )

  const campaign: StudioCampaignDto = await response.json()

  return campaign
}

/**
 * Fetch campaign pages data.
 */
export const getCampaignPagesData = async ({
  campaignId,
  req,
  published = false,
}: GetCampaignDataParams): Promise<StudioCampaignPagesDto> => {
  const response = await APIv2Fetch(
    `/studio-campaigns/${campaignId}/pages?published=${published}`,
    { method: HttpMethod.GET },
    { originalRequest: req },
  )

  const campaignPages: StudioCampaignPagesDto = await response.json()

  return campaignPages
}

/**
 * Fetch LEGACY campaign data.
 */
export const getLegacyCampaignData = async ({
  campaignId,
  req,
}: GetCampaignDataParams): Promise<CampaignDataDto> => {
  const response = await APIv2Fetch(
    `/campaigns/${campaignId}`,
    { method: HttpMethod.GET },
    { originalRequest: req },
  )

  const campaign: CampaignDataDto = await response.json()

  return campaign
}

/**
 * Search the fetched campaign data for a specific page.
 *
 * If a tid (transaction Id) is passed in, the mockPageKey is used to fetch mock data
 * from the /data folder.
 */
export const findCampaignPage = (
  campaignData: StudioCampaignPagesDto,
  pageType: PageType,
  tid?: string,
) => {
  const mockPageKey = getMockPageKey(campaignData.campaign_id, pageType, tid)

  const pagePair = Object.entries(campaignData.pages).find(([pageKey, pageObject]) => {
    if (mockPageKey) {
      // At the moment, this only applies to mock thank you pages
      return pageKey === mockPageKey
    }

    return pageObject.type === pageType
  })

  return pagePair ? pagePair[1] : undefined
}

/**
 * Search the fetched campaign data for a specific page id.
 *
 * This function currently grabs the first page that matches the page type.
 */
export const findCampaignPageId = (campaignData: StudioCampaignPagesDto, pageType: PageType) => {
  const pagePair = Object.entries(campaignData.pages).find(([, pageObject]) => {
    return pageObject.type === pageType
  })

  return pagePair ? pagePair[0] : undefined
}

/**
 * For a raw campaign page, merge its layout and blocks details.
 *
 * Within a campaign page data object, there are two properties to merge:
 * - "layout" - tree that describes the structure/ordering of page blocks
 * - "blocks" - map containing every page block, and its type and props
 *
 * To merge:
 * - Clone the "layouts" property.
 * - Recursively traverse "layouts".
 * - For each node, look up the block in "blocks" and copy its properties over.
 */
export const denormalizeLayoutWithBlocks = (campaignPageData: PageDto): Block => {
  const { layout, blocks } = campaignPageData

  if (!layout || !blocks) {
    throw new Error('Layout or block details missing')
  }

  try {
    const makeBlock = (blockLayout: BlockLayoutDto): Block => ({
      id: blockLayout.id,
      type: blocks[blockLayout.id].type,
      props: blocks[blockLayout.id].props,
      children: [],
    })

    // Recursive function to zipper a layout block's children
    const zipper = (blockLayoutChildren: BlockLayoutDto[]): Block[] =>
      blockLayoutChildren.map(
        (block): Block => ({
          ...makeBlock(block),
          children: zipper(block.children),
        }),
      )

    // Initialize combined pageData object and start the zippering ⏩
    const pageData: Block = {
      ...makeBlock(layout),
      children: zipper(layout.children),
    }

    return pageData
  } catch (e) {
    throw new Error('Unable to merge layout and blocks data', { cause: e })
  }
}

/**
 * If the campaign is inactive reroute to its external url.
 * If there is no external url, reroute to 404
 */
export const isCampaignInactive = (campaign: StudioCampaignDto) => {
  return campaign?.campaign_status != 'active'
}

// * Fetch campaign progress metrics from Classy Search
export const getCampaignProgressMetrics = async (campaignId: string) => {
  const searchRes = await fetch('/api/search/campaigns', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(campaignId),
  })

  const json = await searchRes.json()

  const {
    _source: { stats, fundraising_goal: fundraisingGoal },
    fields: { progress_bar_amount: progressBarAmount },
  } = json?.hits?.hits[0]

  // * Cast all the returned string values as numbers
  const donorCount = Number(stats?.transactions?.count ?? 0)
  const goal = Number(fundraisingGoal?.goal_raw ?? 0).toFixed(2)
  const totalRaised = Number(progressBarAmount[0] ?? 0).toFixed(2)
  const percentToGoal = Number(stats?.percent_to_goal ?? 0).toFixed(2)

  return {
    donorCount,
    goal,
    percentToGoal,
    totalRaised,
  }
}
