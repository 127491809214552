import React from 'react'
import {
  Impact,
  ImpactProps,
  MAX_DONATION_AMOUNT,
  MIN_DONATION_AMOUNT,
  useCustomEventListener,
} from '@classy/campaign-page-blocks'
import { EVENT } from 'models/event'
import { convertAmount, convertAmounts } from 'features/Passport/convertAmounts'
import { CurrencyEventPayload } from 'features/Passport/GlobalCurrencyControl'

export const ImpactController = ({
  currencyCode = 'USD',
  donationAmounts,
  recurringAmounts,
  minDonationAmount = MIN_DONATION_AMOUNT,
  ...rest
}: ImpactProps) => {
  const currencyDetails: CurrencyEventPayload = useCustomEventListener(
    EVENT.PASSPORT_GET_CURRENCY_CONVERSION,
    {
      isLoading: false,
      oldCurrencyCode: currencyCode,
      newCurrencyCode: currencyCode,
      currencyConversionRate: 1,
    },
  )

  const convertedAmounts = React.useMemo(() => {
    const conversionRate = currencyDetails.currencyConversionRate

    return {
      donation: convertAmounts({
        conversionRate,
        amounts: donationAmounts || [],
        campaignCurrencyCode: currencyCode,
        selectedCurrencyCode: currencyDetails.newCurrencyCode,
      }),
      recurring: convertAmounts({
        conversionRate,
        amounts: recurringAmounts || [],
        campaignCurrencyCode: currencyCode,
        selectedCurrencyCode: currencyDetails.newCurrencyCode,
      }),
      min: convertAmount({
        conversionRate,
        amount: minDonationAmount,
        campaignCurrencyCode: currencyCode,
        selectedCurrencyCode: currencyDetails.newCurrencyCode,
      }),
      max: convertAmount({
        conversionRate,
        amount: MAX_DONATION_AMOUNT,
        campaignCurrencyCode: currencyCode,
        selectedCurrencyCode: currencyDetails.newCurrencyCode,
      }),
    }
  }, [
    currencyDetails.currencyConversionRate,
    currencyDetails.newCurrencyCode,
    donationAmounts,
    minDonationAmount,
    recurringAmounts,
    currencyCode,
  ])

  return (
    <Impact
      {...rest}
      isLoading={currencyDetails.isLoading}
      currencyCode={currencyDetails.newCurrencyCode}
      donationAmounts={convertedAmounts.donation}
      recurringAmounts={convertedAmounts.recurring}
      minDonationAmount={convertedAmounts.min}
      maxDonationAmount={convertedAmounts.max}
    />
  )
}
