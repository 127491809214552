import { useImmerReducer } from 'use-immer'
import {
  ActivityFeedItem,
  ActivityFeedItemWithReplies,
  ActivityFeedItemsState,
} from '@classy/campaign-page-blocks'

/**
 * Actions for updating the activity feed items list.
 */
export type FeedItemsAction =
  // System generated feed items and user comments ("FeedItem" in APIv2 land)
  | { type: 'SET_INITIAL_FEED_ITEMS_STATE'; state: ActivityFeedItemsState }
  | { type: 'APPEND_FEED_ITEMS'; items: ActivityFeedItemWithReplies[]; page: number }
  | { type: 'RESET_FEED' }
  | { type: 'LIKE_FEED_ITEM'; feedItemId: string; likeId: string }
  | { type: 'UNLIKE_FEED_ITEM'; feedItemId: string; likeId: string }
  // User comments ("FeedItem" in APIv2 land)
  | { type: 'ADD_USER_COMMENT'; feedItem: ActivityFeedItemWithReplies }
  | { type: 'START_DELETE_USER_COMMENT'; feedItemId: string }
  | { type: 'FINISH_DELETE_USER_COMMENT'; feedItemId: string }
  // Replies ("Comment" in APIv2 land)
  | { type: 'SET_REPLIES'; feedItemId: string; replies: ActivityFeedItem[] }
  | { type: 'ADD_REPLY'; feedItemId: string; feedItemReply: ActivityFeedItem }
  | { type: 'START_DELETE_REPLY'; parentFeedItemId: string; replyId: string }
  | { type: 'FINISH_DELETE_REPLY'; parentFeedItemId: string; replyId: string }
  | { type: 'LIKE_REPLY'; parentFeedItemId: string; replyId: string; likeId: string }
  | { type: 'UNLIKE_REPLY'; parentFeedItemId: string; replyId: string; likeId: string }

const INITIAL: ActivityFeedItemsState = {
  items: [],
  currentPage: 1,
  total: 0,
}

/**
 * Activity feed items list reducer.
 *
 * See https://bitbucket.org/stayclassy/campaign-page-blocks/pull-requests/657 for a useful
 * overview diagram.
 */
function reducer(draft: ActivityFeedItemsState, action: FeedItemsAction) {
  switch (action.type) {
    case 'SET_INITIAL_FEED_ITEMS_STATE': {
      return action.state
    }
    case 'APPEND_FEED_ITEMS': {
      // Before appending the new feed items to our existing list of feed items, we need to ensure
      // that the new feed items don't already exist in the list. This is possible if feed items are
      // created while someone is interacting with the activity feed and clicks to show more without
      // refreshing the page.
      const newFeedItems = action.items.filter(
        (newFeedItem) =>
          !draft.items.find((existingFeedItem) => newFeedItem.id === existingFeedItem.id),
      )

      draft.items.push(...newFeedItems)
      draft.currentPage = action.page
      break
    }
    case 'RESET_FEED': {
      draft.items.splice(4)
      draft.currentPage = 1
      break
    }
    case 'LIKE_FEED_ITEM': {
      const feedItem = draft.items.find((item) => item.id === action.feedItemId)
      if (typeof feedItem === 'undefined') {
        throw new Error(`Unable to find feed item in ActivityFeedItemsState to LIKE_FEED_ITEM`)
      }
      if (feedItem) {
        feedItem.likes = {
          count: feedItem.likes.count + 1,
          likeId: action.likeId,
          memberHasLiked: true,
        }
      }
      break
    }
    case 'UNLIKE_FEED_ITEM': {
      const feedItem = draft.items.find((item) => item.id === action.feedItemId)
      if (typeof feedItem === 'undefined') {
        throw new Error(`Unable to find feed item in ActivityFeedItemsState to UNLIKE_FEED_ITEM`)
      }
      if (feedItem) {
        feedItem.likes = {
          count: feedItem.likes.count - 1,
          likeId: undefined,
          memberHasLiked: false,
        }
      }
      break
    }
    /**
     * Insert a new user comment feed item at the beginning of the list.
     * Set "transitionType" to tell the ActivityFeed page block to perform an insert animation.
     */
    case 'ADD_USER_COMMENT': {
      draft.items.unshift({ ...action.feedItem, transitionType: 'insert' })
      draft.total++
      break
    }
    /**
     * Deleting a user comment feed item is a two-action process:
     * - START_DELETE_USER_COMMENT (this action)
     *   - Set "transitionType" to tell the ActivityFeed page block to perform a delete animation.
     * - FINISH_DELETE_USER_COMMENT
     *   - Remove the user comment feed item from ActivityFeedItemsState.
     */
    case 'START_DELETE_USER_COMMENT': {
      const feedItem = draft.items.find((item) => item.id === action.feedItemId)
      if (typeof feedItem === 'undefined') {
        throw new Error(
          'Unable to find feed item in ActivityFeedItemsState to START_DELETE_USER_COMMENT',
        )
      }
      feedItem.transitionType = 'delete'
      break
    }
    /**
     * See START_DELETE_USER_COMMENT for context.
     */
    case 'FINISH_DELETE_USER_COMMENT': {
      const feedItemIndex = draft.items.findIndex((item) => item.id === action.feedItemId)
      if (feedItemIndex === -1) {
        throw new Error(
          'Unable to find feed item in ActivityFeedItemsState to FINISH_DELETE_USER_COMMENT',
        )
      }
      draft.items.splice(feedItemIndex, 1)
      draft.total--
      break
    }
    case 'SET_REPLIES': {
      const feedItem = draft.items.find((item) => item.id === action.feedItemId)
      if (typeof feedItem === 'undefined') {
        throw new Error('Unable to find feed item in ActivityFeedItemsState to SET_REPLIES')
      }
      feedItem.replies = [...action.replies]
      break
    }
    /**
     * Insert a new reply at the beginning of the replies list.
     * Set "transitionType" to tell the ActivityFeed page block to perform an insert animation.
     */
    case 'ADD_REPLY': {
      const feedItem = draft.items.find((item) => item.id === action.feedItemId)
      if (typeof feedItem === 'undefined') {
        throw new Error('Unable to find feed item in ActivityFeedItemsState to ADD_REPLY')
      }
      feedItem.replies.unshift({ ...action.feedItemReply, transitionType: 'insert' })
      feedItem.repliesCount = feedItem.replies.length
      break
    }
    /**
     * Deleting a reply is a two-action process:
     * - START_DELETE_REPLY (this action)
     *   - Set "transitionType" to tell the ActivityFeed page block to perform a delete animation.
     * - FINISH_DELETE_REPLY
     *   - Remove the reply from ActivityFeedItemsState.
     */
    case 'START_DELETE_REPLY': {
      const parentFeedItem = draft.items.find((item) => item.id === action.parentFeedItemId)
      if (typeof parentFeedItem === 'undefined') {
        throw new Error(
          `Unable to find parent feed item in ActivityFeedItemsState to START_DELETE_REPLY`,
        )
      }
      const replyIndex = parentFeedItem.replies.findIndex((item) => item.id === action.replyId)
      if (replyIndex === -1) {
        throw new Error(`Unable to find reply in ActivityFeedItemsState to START_DELETE_REPLY`)
      }
      parentFeedItem.replies[replyIndex].transitionType = 'delete'
      break
    }
    /**
     * See START_DELETE_REPLY for context.
     */
    case 'FINISH_DELETE_REPLY': {
      const parentFeedItem = draft.items.find((item) => item.id === action.parentFeedItemId)
      if (typeof parentFeedItem === 'undefined') {
        throw new Error(
          `Unable to find parent feed item in ActivityFeedItemsState to FINISH_DELETE_REPLY`,
        )
      }
      const replyIndex = parentFeedItem.replies.findIndex((item) => item.id === action.replyId)
      if (replyIndex === -1) {
        throw new Error(`Unable to find reply in ActivityFeedItemsState to FINISH_DELETE_REPLY`)
      }
      parentFeedItem.replies.splice(replyIndex, 1)
      parentFeedItem.repliesCount--
      break
    }

    case 'LIKE_REPLY': {
      const parentFeedItem = draft.items.find((item) => item.id === action.parentFeedItemId)
      if (typeof parentFeedItem === 'undefined') {
        throw new Error(`Unable to find parent feed item in ActivityFeedItemsState to LIKE_REPLY`)
      }

      const reply = parentFeedItem.replies.find((reply) => reply.id === action.replyId)
      if (typeof reply === 'undefined') {
        throw new Error(`Unable to find reply in ActivityFeedItemsState to LIKE_REPLY`)
      }
      reply.likes = {
        count: reply.likes.count + 1,
        likeId: action.likeId,
        memberHasLiked: true,
      }

      break
    }
    case 'UNLIKE_REPLY': {
      const parentFeedItem = draft.items.find((item) => item.id === action.parentFeedItemId)
      if (typeof parentFeedItem === 'undefined') {
        throw new Error(`Unable to find parent feed item in ActivityFeedItemsState to UNLIKE_REPLY`)
      }

      const reply = parentFeedItem.replies.find((reply) => reply.id === action.replyId)
      if (typeof reply === 'undefined') {
        throw new Error(`Unable to find reply in ActivityFeedItemsState to UNLIKE_REPLY`)
      }
      reply.likes = {
        count: reply.likes.count - 1,
        likeId: undefined,
        memberHasLiked: false,
      }

      break
    }
    default:
      /* @ts-expect-error -- TS complains about action.type because all actions are covered 💪 */
      throw new Error(`Unknown action: ${action.type}`)
  }
}

export const useActivityFeedReducer = () => useImmerReducer(reducer, INITIAL)
