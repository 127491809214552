import { NextApiRequest, NextApiResponse } from 'next'
import crypto from 'crypto'
import Cookies from 'cookies'
import { Cart, CartItem } from 'features/Cart'
import { WhammyApiError } from 'utils/WhammyApiError'

/**
 * Gift catalog cart service
 *
 * Check out src/pages/api/carts/README.md for why most of the fetch calls below use a Whammy API
 * endpoint instead of calling the Cart API directly.
 *
 * Fetch functions, like getCart, createCart, etc., attach a campaignId query parameter so
 * the server can grab the cookie stored when generating the cart for each campaign. The cookie
 * is used to verify that only the user who generated the cart can forward requests to Cart API by
 * proxying through whammy's api.
 */

export const whammyApiCartsPrefix = '/api/carts'

/**
 * Initializes a Cart object with the absolute minimum required properties to successfully call the
 * Cart API create cart endpoint.
 */
export function initCart(campaignId: string): Cart {
  return {
    campaignId,
    currency: 'USD',
    items: [],
  }
}

export function isNewCart(cart: Cart) {
  return !cart.id
}

export async function getCart(cartId: string, campaignId: string): Promise<Cart> {
  const response = await fetch(`${whammyApiCartsPrefix}/${cartId}?campaignId=${campaignId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (response.status !== 200) {
    let message
    switch (response.status) {
      case 403:
        message = 'Not authorized to fetch cart'
        break
      case 404:
        message = 'Not found'
        break
      default:
        message = `Unable to find cart '${cartId}' -  ${response.statusText}, status ${response.status}`
    }
    throw WhammyApiError({ message, statusCode: response.status })
  }

  const json = await response.json()

  // Expired and completed carts cannot be interacted with anymore. We throw an error to ensure
  // the cart state gets cleared
  if (['expired', 'completed'].includes(json.data.attributes?.status)) {
    throw WhammyApiError({ message: 'Cart is not active', statusCode: 404 })
  }

  return { ...json.data.attributes, id: json.data.id }
}

export async function createCart(cart: Cart, campaignId: string): Promise<Cart> {
  const response = await fetch(`${whammyApiCartsPrefix}?campaignId=${campaignId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(cart),
  })

  if (response.status !== 201) {
    let message
    switch (response.status) {
      case 403:
        message = 'Not authorized to create cart'
        break
      default:
        message = `Unable to create an empty cart -  ${response.statusText}, status ${response.status}`
    }
    throw WhammyApiError({ message, statusCode: response.status })
  }

  const json = await response.json()

  return { ...json.data.attributes, id: json.data.id }
}

/**
 * productType = "donation"
 * Minimum properties: productType, qty, unitPrice, designationId
 *
 * productType = recurring"
 * Minimum properties: productType, qty, unitPrice, designationId,
 *                     recurringProperties{ frequency, timeZone }
 */
export async function addCartItem(cartId: string, cartItem: CartItem, campaignId: string) {
  const response = await fetch(
    `${whammyApiCartsPrefix}/${cartId}/cart-items?campaignId=${campaignId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cartItem),
    },
  )

  const json = await response.json()

  if (response.status !== 201) {
    let message
    switch (response.status) {
      case 403:
        message = 'Not authorized to add cart item'
        break
      case 422:
        // Check if error is due to exceeding cart limit
        const isErrorsArray = Array.isArray(json?.errors)
        const cartLimitError = new RegExp('Cart maximum capacity is [0-9]* items.')
        if (isErrorsArray && json.errors[0]?.detail && cartLimitError.test(json.errors[0].detail)) {
          message = 'Cart maximum capacity exceeded'
          break
        }
      default:
        message = `Unable to add item to cart '${cartId}' -  ${response.statusText}, status ${response.status}`
    }
    throw WhammyApiError({ message, statusCode: response.status })
  }

  return { ...json.data.attributes, id: json.data.id }
}

export async function deleteCartItem(cartId: string, cartItemId: string, campaignId: string) {
  const response = await fetch(
    `${whammyApiCartsPrefix}/${cartId}/cart-items/${cartItemId}?campaignId=${campaignId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  if (response.status !== 204) {
    let message
    switch (response.status) {
      case 403:
        message = 'Not authorized to delete cart item'
        break
      default:
        message = `Unable to remove cart item '${cartItemId}' from cart '${cartId}' -  ${response.statusText}, status ${response.status}`
    }
    throw WhammyApiError({ message, statusCode: response.status })
  }

  return
}

export const generateCartCookieValue = (cartId: string, campaignId: string) =>
  crypto
    .createHmac('sha256', process.env.SESSION_SECRET ?? '')
    .update(`${cartId}${campaignId}`)
    .digest('hex')

export const getCartCookieName = (campaignId: string) => `gift-catalog-cart-${campaignId}`

export const isValidCartCookie = (
  req: NextApiRequest,
  res: NextApiResponse,
  cartId: string,
  campaignId: string,
) => {
  const cookies = new Cookies(req, res)
  const cookieValue = cookies.get(getCartCookieName(campaignId))

  const hashedValue = generateCartCookieValue(cartId, campaignId)

  if (cookieValue !== hashedValue) {
    return false
  }

  return true
}
