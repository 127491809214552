import { LikeContext } from '@classy/campaign-page-blocks'
import { whammyFetch } from 'classy-api-proxies/whammy'
import {
  FeedItemCommentPageResponse,
  FeedItemCommentWithContext,
  FeedItemPageResponse,
  FeedItemWithContext,
} from 'features/Block/activity-feed/feed-item.model'
import { HttpMethod } from 'types/http'

/**
 * Fetches the initial activity feed items.
 * See src/pages/api/campaigns/[campaignId]/feed-items.ts.
 */
export const getInitialFeedItems = async (campaignId: string) => {
  const result = await whammyFetch(`/campaigns/${campaignId}/feed-items`, {
    method: HttpMethod.GET,
  })

  const json: FeedItemPageResponse = await result.json()

  return {
    items: json.data,
    total: json.total,
  }
}

/**
 * Fetches feed items for a given page.
 */
export const getMoreFeedItems = async (campaignId: string, page: number) => {
  const result = await whammyFetch(`/campaigns/${campaignId}/feed-items?page=${page}`, {
    method: HttpMethod.GET,
  })

  const json: FeedItemPageResponse = await result.json()

  return json.data
}

/**
 * Adds a single feed item (i.e. post/comment).
 */
export const addFeedItem = async (campaignId: string, body: string) => {
  const result = await whammyFetch(`/campaigns/${campaignId}/feed-items`, {
    method: HttpMethod.POST,
    body: JSON.stringify({
      comment: body,
    }),
  })

  const json: FeedItemWithContext = await result.json()

  return json
}

/**
 * Adds a reply to a single feed item (i.e. post/comment).
 */
export const addComment = async (feedItemId: string, body: string) => {
  const result = await whammyFetch(`/feed-items/${feedItemId}/comments`, {
    method: HttpMethod.POST,
    body: JSON.stringify({ body }),
  })

  const json: FeedItemCommentWithContext = await result.json()

  return json
}

/**
 * Fetches all comments for a single feed item.
 * See src/pages/api/feed-items/[feedItemId]/comments.ts
 * Note, APIv2 comment = UI reply.
 */
export const getComments = async (feedItemId: string) => {
  const result = await whammyFetch(`/feed-items/${feedItemId}/comments`)

  const json: FeedItemCommentPageResponse = await result.json()

  return json.data
}

/**
 * Deletes a feed item.
 * See src/pages/api/feed-items/[feedItemId]/index.ts
 */
export const deleteFeedItem = async (feedItemId: string) => {
  return whammyFetch(`/feed-items/${feedItemId}`, {
    method: HttpMethod.DELETE,
  })
}

/**
 * Deletes a comment.
 * See src/pages/api/comments/[commentId]/index.ts
 * Note, APIv2 comment = UI reply
 */
export const deleteComment = async (commentId: string) => {
  return whammyFetch(`/comments/${commentId}`, {
    method: HttpMethod.DELETE,
  })
}

/**
 * Likes a feed item
 * See src/pages/api/feed-items/[feedItemId]/likes.ts
 */
export const likeFeedItem = async (feedItemId: string) => {
  const result = await whammyFetch(`/feed-items/${feedItemId}/likes`, {
    method: HttpMethod.POST,
  })

  const json = await result.json()
  return json
}

/**
 * Likes a comment
 * See src/pages/api/comments/[commentId]/likes.ts
 * Note, APIv2 comment = UI reply
 */
export const likeComment = async (commentId: string) => {
  const result = await whammyFetch(`/comments/${commentId}/likes`, {
    method: HttpMethod.POST,
  })

  const json = await result.json()
  return json
}

/**
 * Unlikes a like (for a feed item or comment).
 * See src/pages/api/likes/[likeId].ts
 * Note, APIv2 comment = UI reply
 */
export const unlikeLike = async (likeId: string) => {
  return whammyFetch(`/likes/${likeId}`, {
    method: HttpMethod.DELETE,
  })
}

export const getInitialLikesContext: () => LikeContext = () => ({
  count: 0,
  likeId: undefined,
  memberHasLiked: false,
})
