import { CONTAINER_BLOCK_TYPES } from '@classy/campaign-page-blocks'
import { Block, GlobalBlockProps } from './Block'
import { Block as PageBlock } from 'features/Block/block.model'
import { Z_INDEX_MAP } from '@classy/campaign-page-blocks'
import { Environment } from 'utils/environment'

/**
 * See BlockBuilder's docblock for all the details.
 */
export interface LayoutInfo {
  layout?: {
    index: number
    numSections: number
    zIndex: number
  }
  section?: {
    index: number
    hasOneBlock?: boolean
  }
}

interface BlockBuilderProps extends GlobalBlockProps {
  pageData: PageBlock
  layoutInfo?: LayoutInfo
  __ENV?: Environment
}

/**
 * BlockBuilder takes in Block data and renders it.
 * It then recursively does the same for all Child Blocks.
 * Final output is the entire body of a Campaign page.
 * The output body is attached to the DOM at body > div#__next.
 * This is where you enter The Blockiverse. Buckle. Up.
 *
 * ----
 *
 * What's up with the `layoutInfo` prop?
 *
 * ! Naming is hard, the `layoutInfo` prop does not refer to the Layout page block.
 *
 * The `layoutInfo` prop is an object that is built up as the blocks tree is
 * traversed and always reflects the layout state for the current block and its
 * ancestors.
 *
 * ```
 * Canvas           ↓
 *                  {}
 *   Layout         ↓ Initialize layoutInfo, and add Layout details
 *                  {layout: {index:0, numSections:2}}
 *     Section      ↓ Add Section details
 *                  {layout: {index:0, numSections:2}, section: {index:0, ...}}
 *       Title      ↓
 *                  {layout: {index:0, numSections:2}, section: {index:0, ...}}
 *       Paragraph  ↓
 *                  {layout: {index:0, numSections:2}, section: {index:0, ...}}
 *     Section      ↓ Replace previous Section details
 *                  {layout: {index:0, numSections:2}, section: {index:1, ...}}
 *       Donation   ↓
 *                  {layout: {index:0, numSections:2}, section: {index:1, ...}}
 *   Layout         ↓ Replace previous Layout details, and forget previous Section details
 *                  {layout: {index:1, numSections:1}}
 *     Section      ↓ Add Section details
 *                  {layout: {index:1, numSections:1}, section: {index:0, ...}}
 *       FAQ        ↓
 *                  {layout: {index:1, numSections:1}, section: {index:0, ...}}
 * ```
 *
 * See Block.tsx for how `layoutInfo` is used. The properties included in `layoutInfo` are
 * heavily influenced by what is currently needed by page blocks. There is also a very important
 * public service announcement.
 */
export const BlockBuilder = ({ pageData, layoutInfo = {}, member, __ENV }: BlockBuilderProps) => {
  /**
   * If we ever want to add Canvas deets:
   * if (pageData.type === CONTAINER_BLOCK_TYPES.LAYOUT) {
   *   layoutInfo = { canvas: { numLayouts: pageData.children.length } }
   * }
   */

  /**
   * To support certain custom code features, such as a custom nav bar,
   * each Layout needs a stacking z-index higher than any Layouts that follow it
   *
   * Canvas
   *  Layout StyledBackgroundContainer  <- zindex: 500 (HIGHEST_STACKED_LAYOUT_BLOCK)
   *  Layout StyledBackgroundContainer  <- zindex: 499
   *  Layout StyledBackgroundContainer  <- zindex: 498
   * /Canvas
   */
  let zIndex = Z_INDEX_MAP.HIGHEST_STACKED_LAYOUT_BLOCK

  return (
    <Block blockData={pageData} layoutInfo={layoutInfo} member={member} __ENV={__ENV}>
      {pageData.children.map((child, index) => {
        if (child.type === CONTAINER_BLOCK_TYPES.LAYOUT) {
          /**
           * Instantiate a new layoutInfo object.
           * Since we don't track any Canvas details, a Layout block is our starting point.
           * Any previous Layout or Section details are forgotten.
           */
          layoutInfo = {
            layout: {
              index,
              numSections: child.children.length,
              zIndex,
            },
          }
          zIndex -= 1
        } else if (child.type === CONTAINER_BLOCK_TYPES.SECTION) {
          /**
           * Instantiate a new layoutInfo object, copying over the existing Layout block details.
           * Any previous Section details are forgotten/replaced.
           */
          const { layout } = layoutInfo
          layoutInfo = {
            layout,
            section: {
              index,
              hasOneBlock: child.children.length === 1,
            },
          }
        }

        return (
          <BlockBuilder
            pageData={child}
            key={child.id}
            layoutInfo={layoutInfo}
            member={member}
            __ENV={__ENV}
          />
        )
      })}
    </Block>
  )
}
