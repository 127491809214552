import { DonationAmount } from '@classy/campaign-page-blocks'

interface ConvertAmountParams {
  /**
   * Conversion rate between two currency codes.
   */
  conversionRate: number
  /**
   * A numerical amount.
   */
  amount: number
  /**
   * The campaign's raw currency code.
   */
  campaignCurrencyCode: string
  /**
   * The selected currency in the currency drop down.
   */
  selectedCurrencyCode: string
}

export const convertAmount = ({
  conversionRate,
  amount,
  campaignCurrencyCode,
  selectedCurrencyCode,
}: ConvertAmountParams) => {
  let convertedAmount = amount * conversionRate

  /**
   * While rare, campaign admins can set preset amounts using cents.
   * For example, a 9/11 charity wants one of their presets to be $9.11.
   * When showing the amounts in the campaign currency, maintain the cents.
   * When converting the amounts to another currency,
   * they can be rounded to ease spacing/overflow issues in the UI.
   */
  if (selectedCurrencyCode !== campaignCurrencyCode) {
    convertedAmount = Math.round(convertedAmount)
  }

  return convertedAmount >= 1 ? convertedAmount : 1
}

interface ConvertAmountsParams
  extends Pick<
    ConvertAmountParams,
    'conversionRate' | 'campaignCurrencyCode' | 'selectedCurrencyCode'
  > {
  /**
   * An array of numerical amounts.
   */
  amounts: DonationAmount[]
}

export const convertAmounts = ({
  conversionRate,
  amounts,
  campaignCurrencyCode,
  selectedCurrencyCode,
}: ConvertAmountsParams) => {
  return amounts?.map((amount) => ({
    ...amount,
    amount: convertAmount({
      conversionRate,
      amount: amount.amount,
      campaignCurrencyCode,
      selectedCurrencyCode,
    }),
  }))
}
