import { UAParser } from 'ua-parser-js'

export const resolveFaviconUrl = (orgDomainMaskFavicon?: string): string => {
  const isSafari = new UAParser().getBrowser().name === 'Safari'

  if (orgDomainMaskFavicon) return orgDomainMaskFavicon

  /**
   * Safari does not support .svg favicons.
   * If browser is Safari, fallback to /favicon.ico.
   */
  if (isSafari) return '/favicon.ico'

  // Default for Chrome/Firefox
  return '/favicon.svg'
}
