import { DenormalizedPageData, PageConfig } from 'models/pages'
import { PageType } from 'models/pages'
import { denormalizeLayoutWithBlocks, findCampaignPage } from './campaign'
import { Block } from 'features/Block/block.model'
import { FREQUENCY } from '@classy/campaign-page-blocks'
import { getBlocksMap } from 'features/Block/utils/getBlocksMap'

/**
 * Temporary mock data service to pull data from JSON files.
 *
 * ! Do not nuke this mocking service yet, it is used by Lighthouse.
 */

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Returns a campaign object, or a specific page object.
 *
 * In the URL, add:
 * - "?mock" to fetch a "./data/<campaignId>/raw.json"
 */
export const getMockData = async (
  delay = 0,
  campaignId: string,
  pageType: PageType,
  tid?: string,
): Promise<DenormalizedPageData> => {
  await sleep(delay)

  return getMockCampaignData(campaignId, pageType, tid)
}

interface MockPageConfig {
  pageConfig: Partial<PageConfig>
}

export const getMockPageConfig = async (
  campaignId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Not used yet
  pageType: string,
): Promise<MockPageConfig> => {
  const pageConfig: Partial<PageConfig> = {
    campaignId,
    campaignName: 'Fake Campaign',
    checkoutBaseUrl: 'https://mock.org',
    orgId: 'fakeOrgId',
    orgName: 'Fake Organization',
  }

  /* Example overriding pageConfig on a mock campaign
  if (pageType === 'donation' && campaignId === 'gcpoc') {
    pageConfig.campaignId = '524268'
  }
  */

  return { pageConfig }
}

/**
 * Returns a full campaign dataset.
 */
export const getMockCampaignData = async (
  campaignId: string,
  pageType: PageType,
  tid?: string,
): Promise<DenormalizedPageData> => {
  const campaign = await import(`../../data/${campaignId}/raw.json`)
  const { theme, sharedBlocks } = campaign
  const page = findCampaignPage(campaign, pageType, tid)
  const pageData = page ? denormalizeLayoutWithBlocks(page) : ({} as Block)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const blocksMap = getBlocksMap(pageData)

  /* Example overriding individual block(s)
  if (pageType === 'donation' && campaignId === 'activity-feed') {
    blocksMap['activity-feed'][0].props.campaignId = '536228'
  }
  */

  return { campaign_id: campaignId, theme, sharedBlocks, pageData }
}

/**
 * ! In the mock campaign data JSON (i.e. raw.json), we name each "pages" key the same
 * ! as its route. For the moment, this is to make it easier to scan and edit the file.
 *
 * This method is only useful for mock thank you pages. Other mock pages match by "type", not
 * page id.
 */
export const getMockPageKey = (campaignId: string, pageType: PageType, tid?: string) => {
  let pageId = undefined

  if (tid) {
    pageId = `${campaignId}/${pageType}/${tid}`
  }

  return pageId
}

export const mockSocialShareLinks = {
  sms: `sms:?&body=${encodeURIComponent('dev share via SMS test!')}`,
  email: `mailto:?body=${encodeURIComponent(
    'dev share via Email test!',
  )}&subject=${encodeURIComponent('dev testing')}`,
  twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    `dev share to Twitter (I guess X now lol) test!`,
  )}&url=${encodeURIComponent('https://dev.classy-test.org')}`,
  linkedin: 'https://www.linkedin.com',
  facebook: `https://www.facebook.com/dialog/share?app_id=${
    process.env.FACEBOOK_APP_ID
  }&display=popup&href=${encodeURIComponent(
    `https://dev-giving.classy-test.org/campaign/phillyzoo/donate?mock=campaign`,
  )}&redirect_uri=${encodeURIComponent(
    `https://dev-giving.classy-test.org/campaign/phillyzoo/thank-you?mock=campaign`,
  )}`,
  facebookText: 'Facebook Text Share Dev Test',
  facebookImageUrl:
    'https://staging-assets.classy-test.org/4468920/24d31116-37c3-11ee-8db0-0a58a9feac02.webp',
  copy: 'https://dev-giving.classy-test.org/campaign/phillyzoo',
}

export const mockTransactionDetails = {
  amount: '$205.50 USD',
  email: 'donor@classy.org',
  confirmationNumber: '4444',
  href: `${process.env.CLASSY_BASE_URI}/profile/60309/contribution-history`,
  donorCoveredFee: '$5.50',
  orderItems: [
    {
      id: 'id1',
      unitPrice: '$200.00',
      designationName: 'General Fund',
      frequency: FREQUENCY.DAILY,
      timeZone: 'America/Los_Angeles',
    },
  ],
}

// Can use this when developing locally
export const mockTransactionCookie = {
  id: '123abc',
  frequency: 'one-time',
  raw_currency_code: 'USD',
  raw_total_gross_amount: '205',
  member_email_address: 'email@email.com',
  raw_adjustment_amount: '5',
  designation_id: 225031,
}
