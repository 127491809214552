import { css } from '@emotion/react'

/**
 *  The line-clamp property truncates a text at a specified number of lines.
 *  It limits the text, after you tell it the desirable number of lines,
 *  adding an ellipsis after the last word or portion of a word that is demonstrated
 */
export const lineClamp = (lines: number) => {
  return css`
    // https://css-tricks.com/almanac/properties/l/line-clamp/
    // https://github.com/postcss/autoprefixer/issues/1322 <- why we need vendor prefixes
    // Important! This will override "display"
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `
}
