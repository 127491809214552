import { IncomingMessage } from 'http'
import { HttpMethod } from '../../types/http'
import { APIv2Fetch } from 'classy-api-proxies/apiv2'
import { OrganizationTagsPageResponse } from 'models/tags'

export interface GetOrganizationTagsParams {
  organizationId: string
  filter?: string
  req: IncomingMessage
}

/**
 * Fetch org tags server-side
 */
export const getOrganizationTags = async ({
  organizationId,
  filter,
  req,
}: GetOrganizationTagsParams): Promise<OrganizationTagsPageResponse> => {
  const response = await APIv2Fetch(
    `/organizations/${organizationId}/tags${filter ? `?filter=${filter}` : ''}`,
    { method: HttpMethod.GET },
    { originalRequest: req },
  )

  const tags: OrganizationTagsPageResponse = await response.json()

  return tags
}

interface GetOrganizationTagByNameParams {
  organizationId: string
  tagName: string
}

/**
 * Fetch a specific org tag by name client-side
 */
export const getOrganizationTagByName = async ({
  organizationId,
  tagName,
}: GetOrganizationTagByNameParams) => {
  const response = await fetch(`/api/organizations/${organizationId}/tags?filter=name=${tagName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const json = await response.json()

  return json
}
