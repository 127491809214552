import styled from '@emotion/styled'
import { TYPOGRAPHY_PRESETS } from '@classy/campaign-page-blocks'

export const CartButton = styled.button`
  ${TYPOGRAPHY_PRESETS.themeParagraphMd}

  background-color: transparent;
  border: none;
  cursor: pointer;

  color: var(--block-page-header__background-color--contrast);
  height: 32px;
  border-radius: var(--classy-border__radius-xxl);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  z-index: 1;

  & > svg {
    fill: currentcolor;

    & > path {
      fill: currentcolor;
    }
  }

  &:focus-visible {
    outline: 2px dotted var(--classy-color__border--focus);
  }

  &:hover:not(:active) {
    background-color: var(--computed-color__brand-primary--tint);
  }
`
