import { customAlphabet } from 'nanoid'
import { Member } from 'models/member'
import { CartItem } from 'features/Cart'
import { getAnalyticsInstance } from '../analytics'

export interface AnalyticsTrackAddToCartEvent {
  newCartItem: CartItem
  clientIpAddress: string
  cartCount: number
  member: Member | null
}

interface AddToCartContext {
  newCartItem: CartItem
  cartCount: number
  clientIpAddress: string
  /**
   * The current user state.
   * undefined = determining user state has not started
   * null = no user logged in
   * Member = user logged in
   */
  member: Member | null | undefined
}

/**
 * Track Add To Cart button clicks
 */
export const trackAddToCartEvent = (context: AddToCartContext) => {
  const { newCartItem, cartCount, clientIpAddress, member } = context

  const analytics = getAnalyticsInstance()

  /**
   * Generate a unique event id for Facebook Pixel and Meta CAPI, so duplicate events are not sent
   */
  const generateEventId = customAlphabet('0123456789', 12)
  const eventId = `${generateEventId()}.${generateEventId()}`
  const getEventTime = () => Math.round(new Date().getTime() / 1000)

  // Facebook Pixel
  analytics.track(
    'AddToCart',
    {
      content_name: 'Donation',
      donation_frequency: newCartItem?.recurringProperties?.frequency,
      value: newCartItem?.unitPrice,
      currency: 'USD',
      event_id: eventId,
    },
    {
      plugins: {
        all: false,
        'facebook-pixel': true,
      },
    },
  )

  // Meta CAPI
  analytics.track(
    'AddToCart',
    {
      content_name: 'Donation',
      donation_amount: newCartItem?.unitPrice,
      currency: 'USD',
      email: member?.email_address ?? '',
      first_name: member?.first_name ?? '',
      last_name: member?.last_name ?? '',
      client_ip: clientIpAddress ?? '',

      items: cartCount,
      donation_frequency: newCartItem?.recurringProperties?.frequency
        ? newCartItem?.recurringProperties.frequency
        : 'one-time',
      program_designation_name: newCartItem?.name ?? '',
      program_designation_id: newCartItem?.designationId,
      end_date: newCartItem?.recurringProperties?.endDate,
      event_id: eventId,
      event_time: getEventTime(),
    },
    {
      plugins: {
        all: false,
        'meta-capi': true,
      },
    },
  )
}
