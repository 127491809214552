/**
 * fetch() wrapper for calling internal Whammy API.
 *
 * Automatically:
 * - Adds header "Content-Type: application/json"
 * - Prepends "/api" to the fetch URL
 *
 * Example:
 * const response = await whammyFetch('/pet?cat=1', {
 *   method: 'POST',
 *   body: JSON.stringify({ color: 'tuxedo', solitary: false })
 * })
 * const data = await response.json()
 */
export async function whammyFetch(path: string, fetchOptions?: RequestInit): Promise<Response> {
  const fetchOptionsWithCommon = {
    ...fetchOptions,
    headers: {
      'Content-Type': 'application/json',
      ...fetchOptions?.headers,
    },
  }

  return fetch(`/api${path}`, fetchOptionsWithCommon)
}
